import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDydm1FZZ5qr7ds-PygI-uyvZCuFTcCisY",
  authDomain: "uszatekprzedszkole.firebaseapp.com",
  projectId: "uszatekprzedszkole",
  storageBucket: "uszatekprzedszkole.appspot.com",
  messagingSenderId: "114121841595",
  appId: "1:114121841595:web:04399dc85f6af9161c429e",
  measurementId: "G-FYPPT08G5M"
};

  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };