import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta: { pageTitle: "Zaloguj się do panelu", title: 'Logowanie - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
  {
    path: '/',
    redirect: { name: 'Aktualnosci'},
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    children: [
      {
        path: '/aktualnosci',
        name: 'Aktualnosci',
        component: () => import(/* webpackChunkName: "Aktualnosci" */ '../views/Feed.vue'),
        meta: { title: 'Aktualności - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/aktualnosci/:id',
        name: 'Post',
        component: () => import(/* webpackChunkName: "FeedPost" */ '../views/FeedPost.vue'),
        meta: { title: 'Aktualność - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/przedszkole',
        name: 'Przedszkole',
        component: () => import(/* webpackChunkName: "AboutPreschool" */ '../views/AboutPreschool.vue'),
        meta: { pageTitle: "O przedszkolu", title: 'O przedszkolu - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/jadlospis',
        name: 'Jadłospis',
        component: () => import(/* webpackChunkName: "BillOfFare" */ '../views/Dishes.vue'),
        meta: { pageTitle: "Jadłospis na dzień:", title: 'Jadłospis - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/archiwum',
        name: 'Archiwum',
        component: () => import(/* webpackChunkName: "Archives" */ '../views/Archives.vue'),
        meta: { pageTitle: "Archiwum", title: 'Archiwum - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/pliki',
        name: 'Pliki',
        component: () => import(/* webpackChunkName: "FilesToDownload" */ '../views/FilesToDownload.vue'),
        meta: { pageTitle: "Pliki do pobrania", title: 'Pliki do pobrania - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: { name: 'Posts' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    meta: { title: 'Panel - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' },
    children: [
      {
        path: '/dashboard/dishes',
        name: 'Dishes',
        component: () => import(/* webpackChunkName: "Dishes" */ '../views/DashboardDishes.vue'),
        meta: { title: 'Jadłospis - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }  
      },
      {
        path: '/dashboard/dishes/edit/:id',
        name: 'DishesEdit',
        component: () => import(/* webpackChunkName: "DishesEdit" */ '../views/DashboardDishesEdit.vue'),
        meta: { title: 'Jadłospis - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/dashboard/posts',
        name: 'Posts',
        component: () => import(/* webpackChunkName: "Posts" */ '../views/DashboardPosts.vue'),
        meta: { title: 'Wszystkie posty - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }  
      },
      {
        path: '/dashboard/files',
        name: 'Files',
        component: () => import(/* webpackChunkName: "Files" */ '../views/DashboardFiles.vue'),
        meta: { title: 'Pliki do pobrania - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }  
      },
      {
        path: '/dashboard/create',
        name: 'Create',
        component: () => import(/* webpackChunkName: "Create" */ '../views/DashboardCreate.vue'),
        meta: { title: 'Dodawanie - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
      {
        path: '/dashboard/edit/:id',
        name: 'Edit',
        component: () => import(/* webpackChunkName: "Edit" */ '../views/DashboardEdit.vue'),
        meta: { title: 'Edycja - Przedszkole nr 6 im. Misia Uszatka w Ostrzeszowie' }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router